import React, {useContext, useEffect, useState} from 'react';
import {Switch, Route, Redirect, useLocation} from 'react-router-dom';
import {motion} from 'framer-motion';
import Login from "scenes/Login";
import GameGenerator from "pages/GameGenerator";
import Feedback from "pages/Feedback";
import Documentation from "pages/Documentation";
import AuthContext from "context/AuthContext";
import Register from "scenes/Register";
import ForgotPassword from "scenes/ForgotPassword";
import ResetPassword from "scenes/ResetPassword";
import TopCharts from "pages/TopCharts";
import JoinTeam from "scenes/JoinTeam";
import {CompleteSlackAuth} from "components/Sharing/ShareToSlack";
import ProjectShared from "pages/ProjectShared";
import Trends from "pages/Trends";
import Headquarters from "scenes/Headquarters";
import APIContext from "context/APIContext";
import CacheContext from "context/CacheContext";
import ShowIf from "components/common/ShowIf";
import {CircularProgress, Typography} from "@material-ui/core";
import Favorites from "pages/Favorites";
import TutorialsPage from "pages/TutorialsPage";
import LudoErrorPage from "scenes/LudoErrorPage";
import ImageGenerator from "pages/ImageGenerator";
import {isFreePlan} from "scenes/SubscriptionPage/Plans";
import Digest from "pages/Digest";
import CompetitiveAnalysis from "pages/CompetitiveAnalysis";
import ErrorBoundary from "components/utils/ErrorBoundary";
import LeftSidebar from "components/layout-blueprints/LeftSidebar";
import HelpIcon from "components/utils/HelpIcon";
import Search from "pages/Search";
import RegisterPaywall2 from "scenes/RegisterPaywall2";
import RegisterPaywall3 from "scenes/RegisterPaywall3";
import {usePostHog} from "posthog-js/react";
import {StepByStepWrapper} from "./pages/StepByStepGenerator";
import {AssistantChatPage} from "./components/common/AssistantChat";
import {BillingModal} from "./pages/Profile/Profile";
import {useHistory} from "react-router";
import Headquarters2 from "./scenes/Headquarters2";
import GDD4 from "./pages/GDD4";

const healthCheck = 'healthCheck';
const getInitialPayload = 'getInitialPayload';
const getAllActiveFavorites = 'getAllActiveFavorites';

const HEALTHCHECK_INTERVAL = 1000 * 60;

const pageVariants = {
  initial: {
    opacity: 0
  },
  in: {
    opacity: 1
  },
  out: {
    opacity: 0
  }
};

const pageTransition = {
  type: 'tween',
  ease: 'linear',
  duration: 0.3
};

const Routes = () => {

  const posthog = usePostHog();
  const location = useLocation();
  const history = useHistory();
  const {call, loggedIn} = useContext(APIContext);
  const {cache, setCacheValue, setCache} = useContext(CacheContext);
  const {auth, setAuth} = useContext(AuthContext);
  const [loadingCacheData, setLoadingCacheData] = useState(false);
  const [allOk, setAllOk] = useState(false);
  const [openBilling, setOpenBilling] = useState(false);

  const noPaywal = true;

  useEffect(() => {
    function healthCheckWrapper() {
      if (auth.ok || cache.ludoError) {
        call(healthCheck, undefined, {hideErrorMessage: true, noLoadingChange: true}).then(response => {
            if (cache.ludoError === response.ok)
              setCacheValue('ludoError', !response.ok);
          }
        );
      }
    }

    healthCheckWrapper();

    let interval = setInterval(() => {
      healthCheckWrapper();
    }, HEALTHCHECK_INTERVAL);

    return () => {
      clearInterval(interval);
    }
  }, [cache.ludoError, auth.ok]);

  function getGDDComponents(list = []) {
    let result = {}
    list.forEach(component => {
      result[component.section] = component;
    });
    return result;
  }

  const subscription = auth?.subscription;
  const credits = cache?.credits
  const paywall = auth?.user?.paywall;
  const trialExpired = cache?.trialExpired;

  useEffect(() => {
    if (!trialExpired && isTrialExpired(subscription, credits, paywall)) {
      setCacheValue('trialExpired', true);
    }
  },[subscription, credits, paywall, trialExpired]);

  useEffect(() => {
    if (trialExpired) {
      setOpenBilling(true);
      posthog?.capture('show_paywall');
    }
  },[trialExpired]);

  function isTrialExpired(subscription = {}, credits = {}, paywall) {
    let creditsAllSpent = credits.max > 0 && credits.current >= (credits.max + (credits.extra || 0));
    const isFree = isFreePlan(subscription.plan, subscription);
    return isFree && creditsAllSpent;
  }

  useEffect(() => {
    if (loggedIn) {
      setLoadingCacheData(true);
      call(getInitialPayload, undefined, {hideErrorMessage: true}).then(response => {
        if (response.ok && !!response.body.health) {
          let newCache = {
            ...cache,
            projects: response.body.projects,
            genres: response.body.genres,
            colors: response.body.colors,
            styles: response.body.styles,
            componentGroups: response.body.componentGroups,
            perspectives: response.body.perspectives,
            gddComponents: getGDDComponents(response.body.componentList),
            generationStyles: response.body.generationStyles,
            generationTypes: response.body.generationTypes,
            gddPacks: response.body.gddPacks,
            platforms: response.body.platforms,
            teams: response.body.teams,
            ludoError: false,
            credits: response.body.credits,
            plans: response.body.plans,
            loadingTips: response.body.loading_tips,
            whatsNew: response.body.whats_new,
            documentation: response.body.documentation,
            generationSteps: response.body.generation_steps,
            videos: response.body.videos
          };
          let newAuth = response.body.login_payload;

          let project = (newCache.projects || []).find(({_id}) => _id === newAuth.user.selected_project);
          if (!!project) {
            newCache.selectedProjectId = project._id;
          } else if (newCache.projects?.length > 0) {
            newCache.selectedProjectId = newCache.projects[0]._id;
          } else {
            newCache.selectedProjectId = undefined;
            newCache.projectsOpen = true;
          }

          posthog.identify(
            newAuth.user._id,
            { email: newAuth.user.email}
          );

          newCache.trialExpired = isTrialExpired(newAuth.subscription, newCache.credits, newAuth.user.paywall)
          setAuth(newAuth);
          setCache(newCache);

          call(getAllActiveFavorites).then(response => {
            if (response.ok) {
              newCache.allFavorites = response.body;
            }
          })
          setAllOk(true);
          setLoadingCacheData(false);
        } else {

          if (response.status === 403) {
            localStorage.clear();
            history.push('/login');
            setLoadingCacheData(false);
          } else {
            setLoadingCacheData(true);
            setCache(prevState => {
              return {
                ...prevState,
                ludoError: true
              }
            })
          }
        }
      })
    }
  }, [loggedIn, cache.ludoError]);

  const authed = auth.ok === true;

  return (
    <>
      <BillingModal
        open={openBilling}
        onClose={() => setOpenBilling(false)}
      />
      <ShowIf condition={!!cache.ludoError}>
        <LudoErrorPage/>
      </ShowIf>
      <ShowIf condition={!cache.ludoError}>
        <ShowIf condition={loadingCacheData} key="1">
          <div className="text-align-center pt-4 mt-4">
            <CircularProgress size={55}/>
            <Typography>Loading your data...</Typography>
          </div>
        </ShowIf>
        <ShowIf condition={!loadingCacheData} key="2">
          <Switch>
            <Route
              path={["/join-team/:teamId/:email"]}
              component={JoinTeam}
            />
            <Route
              path={["/register/team/:teamId/:email"]}
              component={noPaywal ? RegisterPaywall3 : RegisterPaywall2}
            />
            <Route
              path={["/register/password"]}
              component={noPaywal ? RegisterPaywall3 : RegisterPaywall2}
            />
            <Route
              path={["/register/payment"]}
              component={noPaywal ? RegisterPaywall3 : RegisterPaywall2}
            />
            <Route
              path={["/register/success"]}
              component={noPaywal ? RegisterPaywall3 : RegisterPaywall2}
            />
            <Route
              path={["/register"]}
              component={noPaywal ? RegisterPaywall3 : RegisterPaywall2}
            />
            <Route
              path={["/reset-password/:email/:code"]}
              component={ResetPassword}
            />
            <Route
              path={["/forgot-password"]}
              component={ForgotPassword}
            />
            <Route
              path={["/login"]}
              component={Login}
            />
            {/*<Route path={["/"]}>
                <Redirect to={{pathname: '/login'}}/>
              </Route>*/}
            <PrivateRoute
              authed={authed}
              location={location}
              path={[
                '/',
                '/home',
                '/game-ideator*',
                '/image-generator',
                '/video-generator',
                '/video-generator-plus',
                '/search',
                '/top-charts',
                '/game-elements',
                '/trending/:tab?',
                '/slack',
                '/digest',
                '/competitive-analysis',
                '/market-analysis',
                '/shared/:teamId',
                '/gdd/:projectId?',
                '/game-concept/:projectId?',
                '/favorites',
                '/feedback',
                '/tutorials',
              ]}>
              <ShowIf condition={allOk}>
                <LeftSidebar>
                  <HelpIcon/>
                  <ErrorBoundary fallback="An error has occurred">
                    <Switch location={location} key={location.pathname}>
                      <motion.div
                        initial="initial"
                        animate="in"
                        exit="out"
                        variants={pageVariants}
                        style={{display: "contents"}}
                        transition={pageTransition}>
                        <Switch>
                          <Route
                            path="/gdd/:projectId?"
                            component={GDD4}
                          />
                          <Route
                            path="/game-concept/:projectId?"
                            component={GDD4}
                          />
                          <Route
                            path="/digest"
                            component={Digest}
                          />
                          <Route
                            path="/favorites"
                            component={Favorites}
                          />
                          <Route
                            path="/slack"
                            component={CompleteSlackAuth}
                          />
                          <Route
                            path="/login"
                            component={Login}
                          />
                          <Route
                            path="/game-ideator*"
                            component={GameGenerator}
                          />
                          <Route
                            path="/competitive-analysis"
                            component={CompetitiveAnalysis}
                          />
                          <Route
                            path="/market-analysis"
                            component={CompetitiveAnalysis}
                          />
                          <Route
                            path="/image-generator"
                            component={ImageGenerator}
                          />
                          {/*<Route
                            path="/video-generator-plus"
                            render={props => <VideoGenerator {...props}  limitedVideoGenerator={false}/>}
                          />*/}
                          {/* <Route
                            path="/video-generator"
                            component={VideoGenerator}
                          />*/}
                          <Route
                            path="/shared/:teamId"
                            component={ProjectShared}
                          />
                          <Route
                            path="/top-charts"
                            component={TopCharts}
                          />
                          <Route
                            path="/trending/:tab?"
                            component={Trends}
                          />
                          <Route
                            path="/search"
                            component={Search}
                          />
                          <Route
                            path="/documentation"
                            component={Documentation}
                          />
                          <Route
                            path="/tutorials"
                            component={TutorialsPage}
                          />
                          <Route
                            path="/feedback"
                            component={Feedback}
                          />
                          <Route
                            path="/step-by-step"
                            component={StepByStepWrapper}
                          />
                          <Route
                            path="/assistant"
                            component={AssistantChatPage}
                          />
                          <Route
                            path="/home"
                            component={Headquarters2}
                          />
                          <Route
                            path="/"
                            component={Headquarters2}
                          />
                        </Switch>
                      </motion.div>
                    </Switch>
                  </ErrorBoundary>
                </LeftSidebar>
              </ShowIf>
            </PrivateRoute>
          </Switch>
        </ShowIf>
      </ShowIf>
    </>
  )
};

function PrivateRoute({path, authed, location, children}) {

  const {setCacheValue} = useContext(CacheContext);

  useEffect(() => {
    if (!authed) {
      setCacheValue('unauthenticatedPath', location);
    }
  }, [location, authed]);

  return (
    <Route path={path}>
      {authed === true ?
        children :
        <Redirect to={{pathname: '/login', state: {from: location}}}/>}
    </Route>
  )
}

export default Routes;
