import React, {useEffect, useState} from 'react';
import moment from 'moment';
import useApi from 'useApi';
import useSocket from "useSocket";
import ChartsContext from 'context/ChartsContext';
import AuthContext from 'context/AuthContext';
import SocketContext from 'context/SocketContext';
import APIContext from 'context/APIContext';
import CacheContext, {DEFAULT_CACHE} from 'context/CacheContext';
import usePersistedState from "hooks/usePersistedState";
import Routes from "Routes";
import ShowIf from "components/common/ShowIf";
import {CircularProgress} from "@material-ui/core";
import PerformanceUtils from "helpers/PerformanceUtils";
import jwtDecode from "jwt-decode";
import config from 'config/config.json';
import {hotjar} from 'react-hotjar';
import DetailsPanelContext from "context/DetailsPanelContext";
import useDetailsPanel from "useDetailsPanel";
import * as Sentry from "@sentry/react";
import LudoErrorPage from "scenes/LudoErrorPage";
import CookieConsent, {getCookieConsentValue} from "react-cookie-consent";
import TagManager from 'react-gtm-module';
import {useHistory, useLocation} from "react-router";
import {Userpilot} from 'userpilot'
import {AnimatePresence} from 'framer-motion';
import {ThemeProvider} from '@material-ui/styles';
import MuiTheme from './theme';
import localforage from 'localforage';
import {usePostHog} from "posthog-js/react";

const App = () => {

  const [auth, setAuthState] = usePersistedState('auth', {}, true);
  const [cache, setCache] = useState(DEFAULT_CACHE);

  const [charts, setCharts] = useState([]);
  const [authRecheck, setAuthRecheck] = useState(0);
  const [refreshId, setRefreshId] = useState(0)
  const {status, emit, track, addTrackListener, SOCKET_STATUS, setSocketAuth, instance} = useSocket(cache, setCache);
  const {call, setToken, loading, loggedIn} = useApi(logout, setCache);
  const {
    showGame,
    showGameTopics,
    showIdeator,
    showChat,
    showTrendTopic,
    showImage,
    showCompetitiveAnalysis,
    showDeveloper,
    hidePanel,
    navigatePrevious,
    navigateNext,
    hasPrevious,
    hasNext
  } = useDetailsPanel(cache, setCache, setCacheValue, track);

  const history = useHistory();
  const location = useLocation();
  const posthog = usePostHog();
  const consent = true;

  useEffect(() => {
    if (consent) {
      if (!!config.HOTJAR_ID)
        hotjar.initialize(config.HOTJAR_ID, "6");

      if (!!config.GOOGLE_TAG_ID) {
        TagManager.initialize({gtmId: config.GOOGLE_TAG_ID});
      }

      if(!!config.PUBLIC_POSTHOG_KEY &&!!config.PUBLIC_POSTHOG_HOST && config.ENVIRONMENT !== 'development') {
        posthog.init(config.PUBLIC_POSTHOG_KEY, {
          api_host: config.PUBLIC_POSTHOG_HOST || 'https://app.posthog.com',
          autocapture: false,
          loaded: (posthog) => {
            //if (process.env.NODE_ENV === 'development') posthog.debug()
            //posthog.debug()
          },
        })
      }

    }
  }, [consent])

  useEffect(() => {
    track(`page.${location.pathname}`);
    if (consent) {
      if (!!config.GOOGLE_TAG_ID) {
        TagManager.dataLayer({
          dataLayer: {
            event: 'pageview',
            pagePath: location.pathname
          },
        });
      }
      if (!!config.USERPILOT_TOKEN)
        Userpilot.reload()
    }
  }, [location.pathname, consent]);

  function setAuth(val, incremental = true, decodeToken = true) {
    let newAuth = {...auth, ...val};
    if (!incremental)
      newAuth = {...val, ok: !!val.token};
    if (decodeToken) newAuth.decodedToken = jwtDecode(newAuth.token)
    setAuthState(newAuth);
    setupUserTracking(val)
  }

  function setupUserTracking(val) {
    try {
      if (consent && !!val && !!val.user) {
        if (config.USERPILOT_TOKEN) {
          Userpilot.identify(val.user._id, {
            name: (val.user.first_name || "") + " " + (val.user.last_name || ""),
            email: val.user.email,
            created_at: val.user.registration_date
          });
        }
        Sentry.setUser({id: val.user._id, email: val.user.email});
        if (config.HOTJAR_ID && hotjar) {
          hotjar.identify(val.user._id, {
            first_name: val.user.first_name,
            last_name: val.user.last_name,
            email: val.user.email,
          });
        }
        if (!!config.GOOGLE_TAG_ID) {
          TagManager.dataLayer({
            dataLayer: {
              userId: val.user._id,
            },
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  function setCacheValue(id, value) {
    return setCache(prevState => {
      return {...prevState, [id]: value}
    });
  }

  function addFavoriteToCache(value) {
    return setCache(prevState => {
      return {
        ...prevState,
        allFavorites: PerformanceUtils.editOrAdd(value, prevState.allFavorites, '_id')
      }
    });
  }

  function removeFavoriteFromCache(value) {
    return setCache(prevState => {
      return {
        ...prevState,
        allFavorites: PerformanceUtils.removeFromArray(value, prevState.allFavorites, '_id')
      }
    });
  }

  useEffect(() => {
    setToken(auth.token);
    setSocketAuth(auth);
  }, [auth]);


  useEffect(() => {
    if (auth.decodedToken && auth.decodedToken.exp < moment().unix()) {
      logout();
    }

    let timeout = setTimeout(() => {
      setAuthRecheck(Math.random());
    }, 1000 * 60 * 60);

    return () => {
      clearTimeout(timeout);
    }
  }, [auth, authRecheck]);

  function logout() {
    setAuth({}, false, false);
    localStorage.clear();
    sessionStorage.clear();
    localforage.clear();
    history.push('/');
  }

  return (
    <ThemeProvider theme={MuiTheme}>
      <AnimatePresence key="animate">
        <AuthContext.Provider value={{auth, logout, setAuth}}>
          <SocketContext.Provider value={{emit, status, track, addTrackListener, SOCKET_STATUS, instance}}>
            <APIContext.Provider value={{call, loading, loggedIn, setToken}}>
              <CacheContext.Provider value={{
                cache,
                setCache,
                setCacheValue,
                addFavoriteToCache,
                removeFavoriteFromCache,
              }}>
                <DetailsPanelContext.Provider value={{
                  showGame,
                  showGameTopics,
                  showIdeator,
                  showChat,
                  showTrendTopic,
                  showImage,
                  showDeveloper,
                  showCompetitiveAnalysis,
                  hidePanel,
                  navigatePrevious,
                  navigateNext,
                  hasPrevious,
                  hasNext
                }}>

                  <ChartsContext.Provider value={{charts, setCharts}}>
                    <ShowIf condition={loading.clientOK === true}>
                      <CookieConsent
                        key={refreshId}
                        onAccept={() => setRefreshId(Math.random())}
                        debug={true}
                        visible={getCookieConsentValue(config.CONSENT_COOKIE) === undefined ? "show" : "hidden"}
                        location="bottom"
                        cookieName={config.CONSENT_COOKIE}
                        style={{background: "#131648", zIndex: 9999}}
                        buttonStyle={{color: "white", fontSize: "13px", background: "#488FE6"}}
                        declineButtonStyle={{fontSize: "13px"}}
                      >
                        We are using cookies to give you the best experience on our website.
                      </CookieConsent>
                      <Routes/>
                    </ShowIf>
                    <ShowIf condition={loading.clientOK === false}>
                      <LudoErrorPage/>
                    </ShowIf>
                    <ShowIf condition={!!auth.ok && loading.clientOK === undefined}>
                      <div className="text-align-center m-4">
                        <CircularProgress size={55}/>
                      </div>
                    </ShowIf>
                  </ChartsContext.Provider>
                </DetailsPanelContext.Provider>
              </CacheContext.Provider>
            </APIContext.Provider>
          </SocketContext.Provider>
        </AuthContext.Provider>
      </AnimatePresence>
    </ThemeProvider>
  );
};

export default App;
