import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import {
  AddOutlined,
  ArrowDropDown,
  ArrowDropDownOutlined,
  ArrowDropUpOutlined,
  Delete,
  DeleteOutline,
  DeleteOutlined,
  DownloadOutlined, EditOffOutlined,
  FileCopyOutlined,
  FileDownloadOutlined,
  FileUploadOutlined,
  ImageOutlined,
  Info,
  MoreVert, ShareOutlined,
  SportsEsportsOutlined,
  TryOutlined,
} from "@mui/icons-material";
import GDDContext from "../../context/GDDContext";
import {
  CircularProgress,
  ClickAwayListener,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  Tooltip,
} from "@material-ui/core";
import { HeaderPopup, ShareGDDModal } from "../GDD3/GDDHeader";
import AuthContext from "../../context/AuthContext";
import {
  addNewProject,
  DeleteModal,
  GDDImageAdd,
  GenresSelection,
  getNumberAllowedProjects,
} from "../GDD3/Helpers";
import MyButton, { LockIcon } from "../../components/Controls/MyButton";
import { ImportConcept } from "../../scenes/Headquarters2";
import SocketContext from "../../context/SocketContext";
import APIContext from "../../context/APIContext";
import CacheContext from "../../context/CacheContext";
import { useHistory } from "react-router";
import ShowIf from "../../components/common/ShowIf";
import {
  convertProxyUrl,
  downloadImage,
} from "../../components/common/ImageGallery";
import { MENUS } from "../GDD3/GDDSideMenu/HeaderMenu";
import "./menu.scss";
import { Menu as GDDMenu } from "../GDD3/GDDGameSummary";
import { useListener } from "react-bus";
import ImageEditor from "../ImageGenerator/ImageEditor";
import config from "../../config/config.json";
import { ExportProjectHeader } from "./ExportProject";
import unityIcon from "assets/images/icons/unity.png";

const OPTIONS = [{label: "Edit"}, {label: "Review"}, {label: "Unity Code"}];

const GDD4Menu = ({
  changeAllGdd,
  changeGdd,
  numberAllowedProjects,
  downloadPDF,
  shareToSlack,
  project,
  editable,
  gdd,
  setActiveComponent,
  activeComponent,
  children,
  showConcepts,
  setShowConcepts,
}) => {
  const { exportProject } = useContext(GDDContext);

  return (
    <>
      <Concepts
        project={project}
        setShowConcepts={setShowConcepts}
        showConcepts={showConcepts}
      />
      <div className="top-sections">
        <div className="left-section">
          <TopBarLeft
            numberAllowedProjects={numberAllowedProjects}
            downloadPDF={downloadPDF}
            shareToSlack={shareToSlack}
            project={project}
            editable={editable}
            gdd={gdd}
          />
          {exportProject && <ExportProjectHeader gdd={gdd} />}
          {!exportProject && (
            <DescriptionContent
              gdd={gdd}
              project={project}
              changeAllGdd={changeAllGdd}
              changeGdd={changeGdd}
              activeComponent={activeComponent}
              setActiveComponent={setActiveComponent}
            >
              {children}
            </DescriptionContent>
          )}
        </div>
        <div className="right-section">
          <HeaderImage
            gdd={gdd}
            changeAllGdd={changeAllGdd}
            editable={editable}
            setActiveComponent={setActiveComponent}
            activeComponent={activeComponent}
          />
        </div>
      </div>
    </>
  );
};

const DescriptionContent = ({
  gdd,
  project,
  changeGdd,
  changeAllGdd,
  activeComponent,
  setActiveComponent,
  children,
}) => {
  const { preview } = useContext(GDDContext);

  return (
    <div className="main-description">
      <GenresSelection
        project={project}
        gdd={gdd}
        changeGdd={changeGdd}
        changeAllGdd={changeAllGdd}
        preview={preview}
      />
      <div className="row">
        <IconContent
          activeComponent={activeComponent}
          gdd={gdd}
          changeAllGdd={changeAllGdd}
          setActiveComponent={setActiveComponent}
        />
        {children}
      </div>
    </div>
  );
};

const IconContent = ({
  activeComponent,
  gdd,
  changeAllGdd,
  setActiveComponent,
}) => {
  const addRef = useRef();
  const ref = useRef();

  const { menu, openMenu, closeMenu } = useContext(GDDContext);
  const { cache } = useContext(CacheContext);
  const { gddComponents } = cache;

  const [hoverIcon, setHoverIcon] = useState();
  const [selected, setSelected] = useState();
  const [editImage, setEditImage] = useState(false);

  const iconImage = hoverIcon || gdd.icon;

  const component = gddComponents.header;
  const section = component.section;
  const active = activeComponent?.id === section && selected === "icon";
  const generating = iconImage?.loading;

  let iconWrapperClassName =
    "header-icon-wrapper menu-wrapper image-menu-wrapper";
  if (active) iconWrapperClassName += " selected";
  if (iconImage?.url) iconWrapperClassName += " has-image";
  if (generating) iconWrapperClassName += " generating";

  const iconMenus = { ...MENUS };

  let className = "icon";

  if (!iconImage?.url) {
    className += " no-icon";
    delete iconMenus.editImage;
  }

  if (iconImage?.url) {
    iconMenus.download = {
      id: "download",
      label: "Download Image",
      buttonLabel: (
        <span>
          <FileDownloadOutlined />
        </span>
      ),
    };
    iconMenus.delete = {
      id: "delete",
      label: "Delete",
      buttonLabel: (
        <span>
          <DeleteOutlined />
        </span>
      ),
    };
  }

  function addIcon(images = []) {
    let image = images[0];
    changeAllGdd({ ...gdd, icon: image });
    setSelected();
    closeMenu();
    setHoverIcon();
  }

  function onClickedOption(option, subSection) {
    if (option === "delete") {
      addIcon([]);
    } else if (option === "download") {
      downloadImage(iconImage);
    } else if (option === MENUS.editImage.id) {
      let image = iconImage;
      //openMenu({ option, component, section, subSection, image });
      setEditImage(true);
    } else {
      openMenu({ option, component, section, subSection });
    }
  }

  const onHover = useCallback(
    (data) => {
      if (menu.subSection === "icon") setHoverIcon(data?.image);
    },
    [section, changeAllGdd, gdd, menu]
  );

  const onClick = useCallback(
    (image) => {
      if (!menu || menu.subSection === "icon") addIcon([image]);
    },
    [section, changeAllGdd, gdd, menu]
  );

  useListener(`${section}.hover`, onHover);
  useListener(`${section}.click`, onClick);

  function onEditedImages(images) {
    onClick(images[0]);
    setEditImage(false);
  }

  return (
    <>
      {editImage && (
        <ImageEditor
          url={iconImage?.url}
          initialImage={iconImage}
          onClose={() => setEditImage(false)}
          formValues={{
            image_type: "icon",
            platform: gdd.platform,
            genres: gdd.genres,
            perspective: gdd.perspective,
            art_style: gdd.art_style,
          }}
          onResults={onEditedImages}
        />
      )}
      <div className={className} ref={ref} onClick={setActiveComponent}>
        <ClickAwayListener
          onClickAway={(event) => {
            if (addRef?.current && addRef.current?.contains(event.target))
              return;
            if (selected === "icon") setSelected();
          }}
        >
          <div
            className={iconWrapperClassName}
            key={iconImage?.url}
            onClick={() => setSelected("icon")}
          >
            <div className="icon-gradient" />
            <GDDMenu
              id={`header.icon`}
              options={iconMenus}
              onClick={(option) => onClickedOption(option, "icon")}
            />

            <GDDImageAdd
              className="header-add-element header-icon add-icon menu-wrapper"
              selected={active && selected === "icon"}
              addImage={addIcon}
              content={iconImage}
              loading={!iconImage?.url && generating}
            />
            <IconPlaceholder
              icon={iconImage}
            />
          </div>
        </ClickAwayListener>
      </div>
    </>
  );
};

export const IconPlaceholder = ({ icon }) => {
  return (
    <>
      {icon?.url ? (
        <div
          className="icon-image"
          style={{
            backgroundImage: `url("${convertProxyUrl(icon)}")`,
          }}
        />
      ) : (
        <div className="add-wrapper">
          <SportsEsportsOutlined />
        </div>
      )}
    </>
  );
};

const TopBarLeft = ({
  editable,
  project,
  numberAllowedProjects,
  downloadPDF,
  shareToSlack,
  gdd,
}) => {
  const { openMenu, menu, preview, setPreview, setExportProject } =
    useContext(GDDContext);
  const { auth } = useContext(AuthContext);

  const [tab, setTab] = useState(preview ? 1 : editable ? 0 : 1);
  const [openExport, setOpenExport] = useState(0);
  const [conceptAnchor, setConceptAnchor] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const isOwnProject = project.user_id === auth.user._id;

  function openChat() {
    openMenu({
      component: { section: "chat" },
      option: "chat",
      section: undefined,
    });
  }

  useEffect(() => {
    if (tab === 0) {
      setPreview(false);
      setExportProject(false);
    } else if (tab === 1) {
      setPreview(true);
      setExportProject(false);
    } else if (tab === 2) {
      setPreview(false);
      setExportProject(true);
    }
  }, [tab]);

  const canOpenChat = !menu?.option;

  const allowExportPage = true;

  return (
    <div className="top-bar-left">
      <div className="main-options">
        <Tabs
          value={tab}
          onChange={(event, newValue) => {
            if (allowExportPage) {
              setTab(newValue);
            } else {
              if (newValue <= 1) setTab(newValue);
              else setOpenExport(true);
            }
          }}
        >
          {OPTIONS.map((option, index) => (
            <Tab
              key={option.label}
              label={option.label}
              icon={<img style={{height: "20px"}} src={option.icon}/>}
              className={!editable && index === 0 ? "hide" : ""}
            />
          ))}
        </Tabs>
        {!editable && (
          <div className="read-only">
            <span className="description">This concept is read only</span>
            <span className="hint-wrapper">
              <Tooltip
                title={`Your subscription only allows for ${numberAllowedProjects} active concepts. Upgrade now to increase the number of editable concepts!`}
                arrow
                PopperProps={{
                  disablePortal: true,
                  className:
                    "MuiTooltip-popper MuiTooltip-popperArrow secondary",
                }}
              >
                <span className="hint">
                  <Info className={"font-size-xxl ml-1 text-secondary"} />
                </span>
              </Tooltip>
            </span>
          </div>
        )}
      </div>
      <div className="actions">
        <ShareGDDModal
          open={openExport}
          setOpen={setOpenExport}
          shareToSlack={shareToSlack}
          downloadPDF={() => {
            setTab(0);
            downloadPDF();
          }}
          gdd={gdd}
          title="Share this Game Concept"
        />
        <Tooltip
          title="Share"
          arrow
          PopperProps={{
            className: "MuiTooltip-popper MuiTooltip-popperArrow secondary",
          }}
          placement="top"
        >
          <div
            onClick={() => setOpenExport(true)}
          >
            <ListItemIcon style={{ minWidth: "unset" }}>
              <IconButton
                component="span"
                style={{ width: "44px", height: "40px" }}
              >
                <ShareOutlined className="font-size-xxxxl text-secondary" />
              </IconButton>
            </ListItemIcon>
          </div>
        </Tooltip>
        {isOwnProject && (
          <Tooltip
            title="More Options"
            arrow
            PopperProps={{
              className: "MuiTooltip-popper MuiTooltip-popperArrow secondary",
            }}
            placement="top"
          >
            <div
              onClick={(event) => {
                setConceptAnchor({
                  id: project._id,
                  anchor: event.currentTarget,
                });
              }}
            >
              <ListItemIcon style={{ minWidth: "unset" }}>
                <IconButton
                  component="span"
                  style={{ width: "44px", height: "40px" }}
                >
                  <MoreVert className="font-size-xxxxxxl text-secondary" />
                </IconButton>
              </ListItemIcon>
            </div>
          </Tooltip>
        )}
      </div>
      <Menu
        anchorEl={conceptAnchor?.anchor}
        open={!!conceptAnchor?.id}
        onClose={(event) => {
          event.stopPropagation();
          event.preventDefault();
          setConceptAnchor();
        }}
      >
        <MenuItem
          className="text-red"
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            setDeleteModal(conceptAnchor.id);
            setConceptAnchor();
          }}
        >
          <ListItemIcon>
            <DeleteOutline className="font-size-xxxl" />
          </ListItemIcon>
          <ListItemText primary="Delete Project" />
        </MenuItem>
      </Menu>
      {editable && canOpenChat && (
        <>
          <MyButton
            className="gradient m-0 fit rounded no-shadow"
            onClick={openChat}
          >
            <TryOutlined className="font-size-xl mr-2" />
            Ask Ludo
          </MyButton>
        </>
      )}
      <DeleteModal id={deleteModal} onClose={() => setDeleteModal(false)} />
    </div>
  );
};

const Concepts = ({ project, setShowConcepts, showConcepts }) => {
  const history = useHistory();
  const { track } = useContext(SocketContext);
  const { auth } = useContext(AuthContext);
  const { cache } = useContext(CacheContext);
  const { projects = [], selectedProjectId } = cache;

  const numberAllowedProjects = useMemo(() => {
    return getNumberAllowedProjects(auth);
  }, [auth]);

  function onClickedConcepts() {
    setShowConcepts(!showConcepts);
  }

  function onSelectedProject(projectId) {
    track("gdd.change-concept", { projectId });
    history.push(`/game-concept/${projectId}`);
  }

  const selectedProjectIndex = projects.findIndex(
    (project) => project._id === selectedProjectId
  );

  const filteredProjects = useMemo(() => {
    return projects
      .filter((project) => project._id !== selectedProjectId)
      .slice(0, 5);
  }, [projects, selectedProjectId]);

  return (
    <div className="concepts">
      <div className="selected-concept">
        <ConceptDetails
          key={selectedProjectIndex}
          project={project}
          index={selectedProjectIndex}
          numberAllowedProjects={numberAllowedProjects}
          selectedProjectId={selectedProjectId}
          onClick={() => onSelectedProject(project._id)}
          growOnHover={false}
        />
      </div>
      {!showConcepts && (
        <>
          <NewConcept />

          <div className="other-concepts">
            {filteredProjects.map((project, index) => (
              <ConceptDetails
                key={index}
                project={project}
                index={index}
                numberAllowedProjects={numberAllowedProjects}
                selectedProjectId={selectedProjectId}
                onClick={() => onSelectedProject(project._id)}
                imageOnly={true}
              />
            ))}
          </div>
        </>
      )}

      <MyButton
        className="m-0 no-shadow fit transparent all-concepts"
        onClick={onClickedConcepts}
      >
        <FileCopyOutlined className="font-size-xl mr-2 text-white" />
        All Concepts
        {!showConcepts && (
          <ArrowDropDownOutlined className="font-size-xl ml-1 text-white" />
        )}
        {showConcepts && (
          <ArrowDropUpOutlined className="font-size-xl ml-1 text-white" />
        )}
      </MyButton>
    </div>
  );
};

export const HeaderImage = ({
  gdd,
  changeAllGdd,
  setActiveComponent,
  editable,
  activeComponent,
}) => {
  const { menu, preview } = useContext(GDDContext);
  const { cache } = useContext(CacheContext);
  const { gddComponents } = cache;

  const component = gddComponents.header;
  const section = component.section;
  const active = activeComponent?.id === section;

  const ref = useRef();
  const headerImageRef = useRef();
  const { openMenu, closeMenu } = useContext(GDDContext);

  const [selected, setSelected] = useState();
  const [hoverHeader, setHoverHeader] = useState();
  const [deleteMenuAnchorEl, setDeleteMenuAnchorEl] = useState(null);
  const [editImage, setEditImage] = useState(false);

  const headerImage = hoverHeader || gdd.header;

  const headerUrl = headerImage?.url
    ? convertProxyUrl(headerImage, true)
    : undefined;

  const headerMenus = { ...MENUS };
  const generating = headerImage?.loading;

  let className = "header";
  if (!headerUrl) {
    className += " no-header";
    delete headerMenus.editImage;
  }

  if (headerUrl) {
    className += " has-banner";
  }

  let headerBannerAddClassName = "header-banner-add";
  if (active && selected === "header") headerBannerAddClassName += " selected";
  if (generating) headerBannerAddClassName += " generating";

  const onHover = useCallback(
    (data) => {
      if (menu.subSection === "header") setHoverHeader(data?.image);
    },
    [section, changeAllGdd, gdd, menu]
  );

  const onClick = useCallback(
    (image) => {
      if (!menu || menu.subSection === "header") addHeader([image]);
    },
    [section, changeAllGdd, gdd, menu]
  );

  useListener(`${section}.hover`, onHover);
  useListener(`${section}.click`, onClick);

  function addHeader(images = []) {
    let image = images[0];
    changeAllGdd({ ...gdd, header: image });
    setSelected();
    closeMenu();
    setHoverHeader();
  }

  function onClickedOption(option, subSection) {
    if (option === "delete") {
      addHeader([]);
    } else if (option === "download") {
      downloadImage(headerImage);
    } else if (option === MENUS.editImage.id) {
      setEditImage(true);
    } else {
      openMenu({ option, component, section, subSection });
    }
  }

  function openDeleteContextMenu(event) {
    event.stopPropagation();
    event.preventDefault();
    setDeleteMenuAnchorEl(event.currentTarget);
  }

  function onDelete(event) {
    event.stopPropagation();
    event.preventDefault();
    setDeleteMenuAnchorEl(null);
    onClickedOption("delete", "header");
  }

  function onDownload(event) {
    event.stopPropagation();
    event.preventDefault();
    onClickedOption("download", "header");
  }

  function onEditedImages(images) {
    onClick(images[0]);
    setEditImage(false);
  }

  const addRef = useRef();

  return (
    <>
      {editImage && (
        <ImageEditor
          url={headerImage?.url}
          initialImage={headerImage}
          onClose={() => setEditImage(false)}
          formValues={{
            image_type: "art",
            platform: gdd.platform,
            genres: gdd.genres,
            perspective: gdd.perspective,
            art_style: gdd.art_style,
          }}
          onResults={onEditedImages}
        />
      )}
      <div
        className={className}
        ref={ref}
        onClick={!!editable && !preview ? () => setActiveComponent : undefined}
      >
        <ClickAwayListener
          onClickAway={(event) => {
            if (addRef?.current && addRef.current?.contains(event.target))
              return;
            if (selected === "header") setSelected();
          }}
        >
          <div
            className={headerBannerAddClassName}
            onClick={
              !!editable && !preview ? () => setSelected("header") : undefined
            }
          >
            <div className="header-wrapper">
              <div className="header-gradient" />
              {headerUrl ? (
                <>
                  <img
                    className="hidden-image"
                    src={headerUrl}
                    ref={headerImageRef}
                  />
                  <div className="header-banner">
                    <img src={headerUrl} />
                  </div>
                  <div className="download-delete">
                    <IconButton
                      component="span"
                      style={{ width: "44px", height: "44px" }}
                      onClick={onDownload}
                    >
                      <DownloadOutlined className="font-size-xxxl" />
                    </IconButton>

                    <IconButton
                      component="span"
                      style={{ width: "44px", height: "44px" }}
                      onClick={openDeleteContextMenu}
                    >
                      <MoreVert className="font-size-xxxl" />
                    </IconButton>

                    <ShowIf condition={!!deleteMenuAnchorEl}>
                      <Menu
                        anchorEl={deleteMenuAnchorEl}
                        keepMounted
                        open={!!deleteMenuAnchorEl}
                        onClose={() => setDeleteMenuAnchorEl(null)}
                      >
                        <MenuItem onClick={onDelete}>
                          <ListItemIcon>
                            <Delete />
                          </ListItemIcon>
                          <ListItemText primary="Delete Concept Art Image" />
                        </MenuItem>
                      </Menu>
                    </ShowIf>
                  </div>
                </>
              ) : (
                <>
                  {generating && (
                    <div className="loading-wrapper hide-preview">
                      <CircularProgress size={45} />
                    </div>
                  )}
                  <div className="empty-header">
                    <ImageOutlined />
                  </div>
                </>
              )}
              {!generating && (
                <HeaderPopup
                  addRef={addRef}
                  open={selected === "header"}
                  onClose={() => setSelected()}
                  menus={headerMenus}
                  addImages={(images) => addHeader(images)}
                  onClick={(option) => onClickedOption(option, "header")}
                  headerUrl={headerUrl}
                />
              )}
            </div>
          </div>
        </ClickAwayListener>
      </div>
    </>
  );
};

export const ConceptsSection = ({ onClose }) => {
  const history = useHistory();
  const menuRef = useRef();
  const { track } = useContext(SocketContext);
  const { auth } = useContext(AuthContext);
  const { cache, setCache } = useContext(CacheContext);
  const { projects = [], selectedProjectId } = cache;

  const [deleteModal, setDeleteModal] = useState(false);
  const [conceptAnchor, setConceptAnchor] = useState(null);

  const numberAllowedProjects = useMemo(() => {
    return getNumberAllowedProjects(auth);
  }, [auth]);

  async function onSelectedProject(projectId) {
    track("gdd.change-concept", { projectId });
    history.push(`/game-concept/${projectId}`);
  }

  return (
    <div className="concepts-menu">
      <DeleteModal
        forwardRef={menuRef}
        id={deleteModal}
        onClose={() => setDeleteModal(false)}
      />
      <ClickAwayListener
        onClickAway={(event) => {
          if (menuRef.current?.contains(event.target)) return;
          onClose();
        }}
      >
        <div className="menu-section-content">
          <div className="concept-list">
            <NewConcept text="Start New Concept" />
            {projects.map((project, index) => (
              <ConceptDetails
                key={index}
                project={project}
                index={index}
                numberAllowedProjects={numberAllowedProjects}
                selectedProjectId={selectedProjectId}
                onClick={() => onSelectedProject(project._id)}
                setConceptAnchor={setConceptAnchor}
              />
            ))}
            <Menu
              anchorEl={conceptAnchor?.anchor}
              open={!!conceptAnchor?.id}
              onClose={(event) => {
                event.stopPropagation();
                event.preventDefault();
                setConceptAnchor();
              }}
            >
              <MenuItem
                className="text-red"
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  setDeleteModal(conceptAnchor.id);
                  setConceptAnchor();
                }}
              >
                <ListItemIcon>
                  <DeleteOutline className="font-size-xxxl" />
                </ListItemIcon>
                <ListItemText primary="Delete Project" />
              </MenuItem>
            </Menu>
          </div>
        </div>
      </ClickAwayListener>
    </div>
  );
};

export const ConceptDetails = ({
  project,
  index,
  numberAllowedProjects,
  selectedProjectId,
  onClick,
  setConceptAnchor,
  imageOnly = false,
  growOnHover = true,
}) => {
  const { auth } = useContext(AuthContext);
  const locked = index >= numberAllowedProjects;

  let className = "concept";
  if (project._id === selectedProjectId) className += " active";
  if (locked) className += " locked";
  if (imageOnly) className += " image-only";
  if (growOnHover) className += " hvr-grow";

  const summary = (project.gdd2?.sections || []).find(
    ({ name }) => name === "summary"
  );

  const icon = project.gdd2?.icon;

  let label = summary?.value?.title || "Untitled";
  if (imageOnly && locked) label += ` - This concept is read only`;

  const isOwnProject = project.user_id === auth.user._id;

  return (
    <div key={project.value} className={className} onClick={onClick}>
      <Tooltip
        PopperProps={{
          disablePortal: false,
          className: "MuiTooltip-popper secondary",
        }}
        open={imageOnly ? undefined : false}
        title={label}
        placement="top"
      >
        {icon?.url ? (
          <img className="icon" src={convertProxyUrl(icon)} />
        ) : (
          <div className="icon-placeholder">
            <SportsEsportsOutlined />
          </div>
        )}
      </Tooltip>
      {!imageOnly && <span className="text-truncate d-block">{label}</span>}
      {locked &&
        (imageOnly ? (
          <div className="lock-icon">
            <LockIcon />
          </div>
        ) : (
          <Tooltip
            PopperProps={{
              disablePortal: false,
              className: "MuiTooltip-popper secondary",
            }}
            title="This concept is read only"
          >
            <div className="lock-icon">
              <EditOffOutlined />
            </div>
          </Tooltip>
        ))}
      {isOwnProject && setConceptAnchor && (
        <IconButton
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            setConceptAnchor({
              id: project._id,
              anchor: event.currentTarget,
            });
          }}
          id="gdd.concept-more"
        >
          <ArrowDropDown
            className="font-size-xxxl"
            style={{ marginTop: "5px" }}
          />
        </IconButton>
      )}
    </div>
  );
};

export const NewConcept = ({ text }) => {
  const history = useHistory();
  const { auth } = useContext(AuthContext);
  const { call } = useContext(APIContext);
  const { cache, setCache } = useContext(CacheContext);

  const { track } = useContext(SocketContext);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [importConcept, setImportConcept] = useState(false);

  const defaultPlatform = auth.user.platform;

  function onClickImport() {
    track("gdd.import");
    setImportConcept(true);
    setMenuAnchorEl(null);
  }

  async function addProject() {
    track("gdd.new-project");
    let project = await addNewProject(
      cache.projects,
      call,
      setCache,
      defaultPlatform
    );
    if (project) {
      let path = {
        pathname: "/game-concept/" + project._id,
      };
      history.push(path);
    }
  }

  return (
    <div className="new-concept">
      <div className="button-wrapper hvr-grow">
        <IconButton
          onClick={(event) => setMenuAnchorEl(event.currentTarget)}
          id="gdd.new"
          name="Start New Concept"
          className="gradient square transform-none"
        >
          <Tooltip
            title="New Game Concept"
            PopperProps={{
              disablePortal: false,
              className: "MuiTooltip-popper secondary",
            }}
            placement="top"
          >
            <div
              style={{ display: "flex", minWidth: "30px" }}
              className="w-100"
            >
              <AddOutlined className="font-size-xl pointer text-white" />
              {text ? (
                <span className="text-white font-size-sm label">{text}</span>
              ) : null}
            </div>
          </Tooltip>
        </IconButton>
      </div>
      {menuAnchorEl && (
        <Menu
          anchorEl={menuAnchorEl}
          keepMounted
          open={!!menuAnchorEl}
          onClose={() => setMenuAnchorEl(null)}
        >
          <MenuItem onClick={addProject}>
            <ListItemIcon>
              <AddOutlined />
            </ListItemIcon>
            <ListItemText primary="Create New Game Concept" />
          </MenuItem>
          <MenuItem onClick={onClickImport}>
            <ListItemIcon>
              <FileUploadOutlined />
            </ListItemIcon>
            <ListItemText primary="Import Existing Concept" />
          </MenuItem>
        </Menu>
      )}
      {importConcept && (
        <ImportConcept onClose={() => setImportConcept(false)} />
      )}
    </div>
  );
};

export default GDD4Menu;
